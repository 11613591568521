export default {
  headerImage: {
    maxHeight: ['auto', '650px'],
    objectFit: 'cover',
    width: '100vw',
    opacity: 0.3,
    position: 'relative',
    borderRadius: ['0px', '40px'],
  },
  avatar: {
    width: ['125px', '225px'],
    height: ['125px', '225px'],
  }
}
