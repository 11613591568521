/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Image } from 'theme-ui'
import React from 'react'
import { NavBar } from '/components/layout/NavBar'
import { NavMobile } from '/components/layout/NavMobile'
import { LanguageSwitch } from '/components/layout/LanguageSwitch'
import styles from './styles'
import Carousel from '/components/Commons/Carousel'
import useResponsive from '/hooks/useResponsive'

export const Header = ({ sideMenu, laguageSwitch, carrousel, ...props }) => {
  const logo = '/static/aia-tth-logo.png'

  const { isMobile } = useResponsive(props.isMobile)

  return (
    <header sx={styles.header}>
      {isMobile ? (
        <React.Fragment>
          <Image {...props} src={logo} sx={styles.logoContainer} />
          <Image src="/static/home-shape.png" sx={styles.shapeBox} />
          <NavMobile navItems={sideMenu} />
        </React.Fragment>
      ) : (
        <NavBar navItems={sideMenu} />
      )}
      <LanguageSwitch isMobile={isMobile} languageSwitch={laguageSwitch} />
      <Carousel
        slides={carrousel}
        imageStyle="headerImage"
        titleStyle="headerTitle"
        subtitleStyle="headerSubtitle"
        buttonStyle="headerButton"
      />
    </header>
  )
}
