import { v4 as uuidv4 } from 'uuid'
import API from '/repository/repoAPI'

const tracking = () => {
  let userId = null

  if (typeof window !== 'undefined') {
    const localIdentifier = 'user_analytics_id'
    userId = localStorage.getItem(localIdentifier)
    if (!userId) {
      userId = uuidv4()
      localStorage.setItem(localIdentifier, userId)
    }
  }

  const sendCSMEvent = (
    eventName,
    action,
    category,
    attribute,
    locale,
    // eslint-disable-next-line no-unused-vars
    userNumber,
  ) => {
    const body = {
      userId,
      eventName,
      action,
      category,
      attribute,
      locale,
    }
    API.sendTracking('')({ body })
  }
  const sendAdobeEvent = (eventName, props) => {
    if (window?._satellite !== undefined) {
      window._satellite.track(eventName, props)
    }
  }

  return {
    sendCSMEvent,
    sendAdobeEvent,
  }
}

export default tracking
