import { TRACKING_EVENT_NAME, TRACKING_ATTRIBUTE } from '/constants/siteTagging'

export const getTrackingInfo = (link, isMobile) => {
  if (link === '/headerchallenge' && isMobile) {
    return {
      eventName: TRACKING_EVENT_NAME.hc_mobile_click,
      eventAttribute: TRACKING_ATTRIBUTE.header_challenge_mobile,
    }
  }

  const tracking = {
    '/': {
      eventName: TRACKING_EVENT_NAME.home_click,
      eventAttribute: TRACKING_ATTRIBUTE.home,
    },
    '/playerofthemonth': {
      eventName: TRACKING_EVENT_NAME.potm_click,
      eventAttribute: TRACKING_ATTRIBUTE.potm,
    },
    '/headerchallenge': {
      eventName: TRACKING_EVENT_NAME.hc_desktop_click,
      eventAttribute: TRACKING_ATTRIBUTE.header_challenge_desktop,
    },
    '/coachingcorner': {
      eventName: TRACKING_EVENT_NAME.cc_click,
      eventAttribute: TRACKING_ATTRIBUTE.coaching_corner,
    },
    '/contentlibrary': {
      eventName: TRACKING_EVENT_NAME.cl_click,
      eventAttribute: TRACKING_ATTRIBUTE.content_library,
    },
    '/partnership': {
      eventName: TRACKING_EVENT_NAME.fom_click,
      eventAttribute: TRACKING_ATTRIBUTE.aia_info,
    },
  }

  return tracking[link] || link
}

export default getTrackingInfo
