export const TRACKING_EVENT_NAME = {
  home_click: 'Clicks home icon',
  menu_bar: 'Menu bar',
  potm_click: 'User lands on POTM home page',
  cc_click: 'User clicks Coaching Corner launch button',
  cl_click: 'Content Library explore button clicked',
  hc_desktop_click: 'Header challenge –button clicked on desktop',
  hc_mobile_click: 'Header challenge –button clicked on mobile',
  fom_click: 'Find out more –learn button clicked',
  visit_aia: 'Clicks visit AIA CTA',
  video_play: 'Play of video content',
  video_one_quarter_watched: 'Video content watched 25%',
  video_half_watched: 'Video content watched 50%',
  video_three_quarter_watched: 'Video content watched 75%',
  video_full_watched: 'Video content watched 100%',
  navigation_click: 'navigation_menu_click',
  banner_impression: 'banner_impression',
  banner_click: 'banner_click',
  external_link_click: 'external_link_click',
  language_click: 'language_button_click',
  vote_button: 'User clicks any vote button',
  view_stats_button: 'User clicks any view stats button',
  submit_button: 'User clicks Submit button',
  product_banner: 'User clicks product banner',
  facebook_icon: 'User clicks Facebook icon',
  instagram_icon: 'User clicks Instagram icon',
  twitter_icon: 'User clicks Twitter icon',
  whatsapp_icon: 'User clicks Whatsapp icon',
  view_stats: 'potm_view_stats',
  survey_initiation: 'potm_survey_initiation',
  survey_submission: 'potm_survey_submission',
  survey_error: 'potm_survey_error',
  social_interaction: 'social_interaction',
}

export const TRACKING_ACTION = {
  clicked_cta: 'Clicked CTA',
  visit_aia_cta: 'Visit AIA CTA clicked',
  video_play: 'Video play',
  video_one_quarter_watched: 'Video view 25%',
  video_view: 'Video view',
}
export const TRACKING_CATEGORY = {
  homepage: 'Homepage',
  menu_bar: 'Menu bar',
  fom_page: 'AIA info page',
  onebillion_page: 'AIA one billion page',
  potm_vote_page: 'POTM vote page',
  potm_entry_page: 'POTM entry page',
  potm_survey_page: 'POTM survey page',
  potm_submission_page: 'POTM submission page',
}
export const TRACKING_ATTRIBUTE = {
  menu_bar: 'Menu bar',
  home: 'Home',
  potm: 'POTM',
  coaching_corner: 'Coaching Corner',
  content_library: 'Content Library',
  header_challenge_mobile: 'Header challenge – Mobile',
  header_challenge_desktop: 'Header challenge – Desktop',
  aia_info: 'AIA info',
  aia_one_billion: 'AIA One billion',
  visit_aia: 'Visit AIA',
  vote: 'Vote',
  view_stats: 'View stats',
  entry_complete: 'Entry complete',
  survey_complete: 'Survey complete',
  product_banner: 'Product banner',
  facebook_icon: 'Facebook icon',
  instagram_icon: 'Instagram icon',
  twitter_icon: 'Twitter icon',
  whatsapp_icon: 'Whatsapp icon',
}

export const PATHS_CATEGORY = {
  '/survey': TRACKING_CATEGORY.potm_survey_page,
  '/contact': TRACKING_CATEGORY.potm_entry_page,
  '/submission': TRACKING_CATEGORY.potm_submission_page,
}
